const stops = [
  "Schwedenplatz",
  "Siebenhirten",
  "Karlsplatz",
  "Stephansplatz",
  "Hauptbahnhof",
  "Keplerplatz",
  "Praterstern",
  "Schottenring",
  "Schottentor",
  "Schönbrunn",
  "Westbahnhof",
  "Landstraße",
  "Schwedenplatz",
  "Reumannplatz",
  "Kagran",
  "Hütteldorf",
  "Ottakring",
  "Heiligenstadt",
  "Spittelau",
  "Volkstheater",
  "Rathaus",
  "Schottentor",
  "Schottenring",
  "Praterstern",
  "Karlsplatz",
  "Stephansplatz",
  "Hauptbahnhof",
  "Oberlaa",
];

export { stops };
